import React, { FunctionComponent } from "react";
import { Layout } from "../components/layout";

export const ContactSuccess: FunctionComponent = () => {
  return (
    <Layout>
      <h1 className="text-3xl text-center my-6 uppercase">Gracias</h1>
      <p className="text-center">
        Hemos recibido tu mensaje. Nos contactaremos en cuanto sea posible.
      </p>
      <img
        className="max-w-4xl mx-auto my-6 max-w-full"
        src="/thank-you.jpeg"
      ></img>
    </Layout>
  );
};

export default ContactSuccess;
